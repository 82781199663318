
import Vue from 'vue';
import Component from 'vue-class-component'

import ImageUpload from '@/components/dialogs/ImageUpload.vue';
import ImageCard from '@/components/cards/ImageCard.vue';
import SearchCard from '@/components/cards/SearchCard.vue';
import NotFoundCard from '@/components/cards/NotFoundCard.vue';

import { categories } from '@/constants';
import { Image } from '@/entities/images/Image';

@Component({
  components: {
    ImageUpload,
    ImageCard,
    SearchCard,
    NotFoundCard
  }
})
export default class ImagesList extends Vue{
  images: Image[] = [];

  selectedCategories: string[] = [];
  categories = categories;

  search = "";
  dialog = false;

  mounted() {
    this.fetchImages();
  }

  get filtered() {
    return this.$helpers.filterImages(this.images, this.search, this.selectedCategories);
  }

  async fetchImages() {
    this.$images.list().then(images => {
      this.images = images;
    });
  }
}
